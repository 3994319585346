<template>
  <div style="margin-left: 5px;margin-right: 5px;font-size: 12px;">
    <p>我们仅会出于正当、必要、特定的目的共享您的信息。对我们与之共享信息的第三方服务商，我们会要求他们履行相关保密义务并采取相应的安全措施。该等第三方社交媒体或其他服务由相关的第三方负责运营， 您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非我方隐私政策）约束，您需要仔细阅读其条款。我方隐私政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益。</p>
    <p>我们提供的产品与/或服务可能接入或链接至第三方提供的广告、社交媒体或其他服务（包括网站或其他服务形式）。包括：</p>
    <div class="tableData">
      <table border="1" rules="all">
        <tr>
          <td>微信支付(com.tencent.mm.opensdk)</td>
          <td>腾讯科技(深圳)有限公司</td>
          <td>在应用内使用微信支付</td>
          <td>在用户每次选择第三方支付方式时使用</td>
          <td>IMEI、IMSI、Android ID、MAC地址、OAID、操作系统、微信账号信息、已安装应用列表信息、支付订单标识、支付状态信息、存储权限</td>
          <td>SDK本机采集</td>
          <td>
            <a href="https://pay.weixin.qq.com/">官网链接：https://pay.weixin.qq.com/</a><br />
            <a href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml">隐私政策链接：https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</a>
          </td>
        </tr>
        <tr>
          <td>支付宝支付(com.alipay.sdk)</td>
          <td>蚂蚁金服(杭州)网络技术有限公司</td>
          <td>在应用内使用支付宝支付</td>
          <td>在用户每次选择第三方支付方式时使用</td>
          <td>IMEI、IMSI、Android ID、MAC地址、OAID、硬件序列号、ICCID、SSID、BSSID、传感器信息、网络类型、运营商信息、WIFI状态、系统设置、系统属性、设备品牌、设备型号、操作系统</td>
          <td>SDK本机采集</td>
          <td>
            <a href="https://open.alipay.com/">官网链接：https://open.alipay.com/</a><br />
            <a href="https://opendocs.alipay.com/open/54/01g6qm">隐私政策链接：https://opendocs.alipay.com/open/54/01g6qm</a>
          </td>
        </tr>
        <tr>
          <td>微信(com.tencent.mm.opensdk)</td>
          <td>腾讯科技(深圳)有限公司</td>
          <td>分享内容至微信客户端</td>
          <td>在用户进行信息分享时使用</td>
          <td>IMEI、IMSI、Android ID、MAC地址、OAID、操作系统、微信账号信息、已安装应用列表信息、存储权限、分享图片或内容</td>
          <td>SDK本机采集</td>
          <td>
            <a href="https://open.weixin.qq.com/">官网链接：https://open.weixin.qq.com/</a><br />
            <a href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8">隐私政策链接：https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</a>
          </td>
        </tr>
        <tr>
          <td>高德地图</td>
          <td>高德软件有限公司</td>
          <td>在应用内使用高德地图</td>
          <td>在用户进行模拟位置使用</td>
          <td>IMEI、IMSI、Android ID、MAC地址、OAID、操作系统、地理位置信息、已安装应用列表信息、存储权限</td>
          <td>SDK本机采集</td>
          <td>
            <a href="https://mobile.amap.com/">官网链接：https://mobile.amap.com/</a><br />
            <a href="https://cache.amap.com/h5/h5/publish/238/index.html">隐私政策链接：https://cache.amap.com/h5/h5/publish/238/index.html</a>
          </td>
        </tr>
        <tr>
          <td>百度地图</td>
          <td>百度网讯科技(北京)有限公司</td>
          <td>在应用内使用百度地图</td>
          <td>在用户进行模拟位置使用</td>
          <td>IMEI、IMSI、Android ID、MAC地址、OAID、操作系统、地理位置信息、已安装应用列表信息、存储权限</td>
          <td>SDK本机采集</td>
          <td>
            <a href="https://lbsyun.baidu.com/">官网链接：https://lbsyun.baidu.com/</a><br />
            <a href="https://lbsyun.baidu.com/index.php?title=openprivacy">隐私政策链接：https://lbsyun.baidu.com/index.php?title=openprivacy</a>
          </td>
        </tr>
        <tr>
          <td>腾讯地图</td>
          <td>腾讯科技(北京)有限公司</td>
          <td>在应用内使用腾讯地图</td>
          <td>在用户进行模拟位置使用</td>
          <td>IMEI、IMSI、Android ID、MAC地址、OAID、操作系统、地理位置信息、已安装应用列表信息、存储权限</td>
          <td>SDK本机采集</td>
          <td>
            <a href="https://map.qq.com/mobile/">官网链接：https://map.qq.com/mobile/</a><br />
            <a href="https://privacy.qq.com/document/preview/29cd78d3d9da4da2b340bedb2d186612">隐私政策链接：https://privacy.qq.com/document/preview/29cd78d3d9da4da2b340bedb2d186612</a>
          </td>
        </tr>
        <tr>
          <td>友盟-啄木鸟(com.uc.crashsdk,com.uc.crashsdk,com.efs,com.umeng.analytics,com.umeng.union)</td>
          <td>友盟同欣（北京）科技有限公司</td>
          <td>应用性能监控平台U-APM</td>
          <td>在用户使用App时</td>
          <td>IMEI、IMSI、Android ID、MAC地址、OAID、 地理位置信息、设备型号、设备识别码、操作系统、屏幕分辨率、传感器参数、设备制造商、操作系统版本、语言所在地、网络状态信息、IP地址、获取运行中进程信息、已安装应用列表信息、存储权限</td>
          <td>SDK本机采集</td>
          <td>
            <a href="https://www.umeng.com/">官网链接：https://www.umeng.com/</a><br/>
            <a href="https://www.umeng.com/page/policy">隐私政策链接：https://www.umeng.com/page/policy</a>
          </td>
        </tr>
        <tr>
          <td>个推(com.getui:gtsdk)</td>
          <td>每日互动股份有限公司</td>
          <td>为App用户提供消息推送技术服务</td>
          <td>在用户使用App时</td>
          <td>IMEI、IDFA、Android ID、MAC、OAID、Advertising ID、Serial Number、IMSI、UAID、ICCID、IDFV、CAID、GAID、地理位置信息、设备型号、设备识别码、操作系统、屏幕分辨率、传感器参数、设备制造商、操作系统版本、语言所在地、网络状态信息、IP地址、获取运行中进程信息、已安装应用列表信息、存储权限</td>
          <td>SDK本机采集</td>
          <td>
            <a href="https://www.getui.com/">官网链接：https://www.getui.com/</a><br/>
            <a href="https://docs.getui.com/privacy/">隐私政策链接：https://docs.getui.com/privacy</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  props: {},
  data() {
    return {
      isLoading: true
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: no-repeat 0 0;
    background-size: 100% auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    height: auto;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    background-color: #ffffff;
  }
  li {
    list-style: none;
    padding: 0.5em 0;
    width: 100%;
  }
  .tableData {
    width: 100%;
    overflow: auto;
  }
  table {
    border-collapse: collapse;
    width: 600px;
  }
  table,
  table tr th,
  table tr td {
    white-space: nowrap;
  }
  th,
  td {
    padding: 0.5em;
  }
  #app {
    font-family: -apple-system-font, BlinkMacSystemFont, "Helvetica Neue",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei",
    Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.8em;
  }
  .right {
    text-align: right;
  }
  .middle {
    text-align: center;
  }
  p {
    line-height: 1.6em;
    word-break: break-all;
    padding: 0.4em 0;
  }
  h3,
  h4 {
    padding: 0.6em 0;
    margin-bottom: 0.5em;
  }
  .layout {
    padding: 1em 4%;
    letter-spacing: 1px;
  }
  .title {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 2em;
    text-align: center;
  }
  .sub_list > * {
    padding: 0.6em 0;
  }
</style>